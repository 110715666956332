<template>
  <div class="container">
    <div class="row py-5 auth-row">
      <div class="col-12 col-md-5">
        <img
          class="img-fluid"
          src="@/assets/img/cedaci-img.jpg"
          alt="CEDaCI Main image"
        />
      </div>
      <div class="col-12 col-md-7 text-center">
        <h1 class="title-w2" style="margin-bottom: 3rem">Welcome to Compass</h1>
        <form @submit.prevent="login">
          <div class="form-item w-75 mb-4">
            <label class="form-label">Username</label>
            <input
              type="text"
              class="form-control"
              placeholder="Your username"
              v-model="form.username"
              required
            />
          </div>
          <div class="form-item input-group w-75 mb-4">
            <label class="form-label">Password</label>
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              placeholder="Your password"
              v-model="form.password"
              required
            />
            <span class="input-group-text cursor-pointer"
              ><i
                :class="
                  showPassword ? 'bi bi-eye-slash-fill' : 'bi bi-eye-fill'
                "
                @click="showPassword = !showPassword"
              ></i
            ></span>
          </div>
          <button class="btn w-75 mt-2 mb-4">Login</button>
          <p>or</p>
          <router-link :to="{ name: 'Register' }"
            ><button class="btn-outlined w-75">Sign up</button></router-link
          >
        </form>
        <p class="link forgot-password" @click="showModal = true">
          Forgot your password?
        </p>
      </div>
      <el-dialog v-model="showModal" title="Forgot your password?">
        <div class="row modal-form-row">
          <div class="col-12">
            <div class="form-item">
              <label class="form-label">Email address</label>
              <input
                type="email"
                class="form-control"
                placeholder="Please enter your email address"
                v-model="email"
                required
              />
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="showModal = false">Cancel</el-button>
            <el-button type="primary" @click="forgotPassword"
              >Confirm</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Feedback from '@/utils/services/Feedback';

// Need validation
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      email: '',
      showModal: false,
      showPassword: false,
    };
  },
  methods: {
    login() {
      this.$store.dispatch('login', this.form).then((res) => {
        if (res.status === 200) {
          this.$router.push({ name: 'Compass' });
        } else
          Feedback.showError(
            'There was an error logging in. Please try again.'
          );
      });
    },
    forgotPassword() {
      this.$store.dispatch('forgotPassword', this.email).then((res) => {
        if (res.status === 200)
          Feedback.showSuccess(
            'An email has been sent to reset your password.'
          );
        else
          Feedback.showError(
            'There was an error sending the email. Please try again.'
          );
      });
    },
  },
};
</script>

<style lang="scss">
.forgot-password {
  margin-top: 3rem;
}
.el-dialog {
  width: 90% !important;
}

@include bp-up(md) {
  .el-dialog {
    width: 70% !important;
    .el-dialog__body {
      .form-control {
        max-width: 80%;
        margin: 0 auto;
      }
    }
  }
}

@include bp-up(lg) {
  .el-dialog {
    width: 60% !important;
    .el-dialog__body {
      .form-control {
        max-width: 70%;
        margin: 0 auto;
      }
    }
  }
}
</style>
